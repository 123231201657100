<template>
  <div class="pt-10">
    <vx-card class="mb-base" v-if="editAdmin === false">
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex item-center">
            <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'director-learning-centre-view', params:{ id:adminDetails.learningCenterId }}"
            >{{ adminDetails ?  adminDetails.learningcenterDetails[0].name : '' }}
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>{{ adminDetails.fullName }}</a>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>Admin View</a>
          </li>
        </ul>
      </div>

      <div class="flex items-center my-10">
        <vs-button @click="editAdmin = true" class="md:ml-auto">Edit</vs-button>
      </div>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="admin-detail-wrap flex-wrap"
      >
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="vs-con-loading__container mt-5"
        >
          <div class="w-3/5 sm:w-1/5 p-5">
            <div class="img-container w-full flex items-center">
              <!-- <img :src = "centerDetails.logo" /> -->
              <img
                v-bind:src="adminDetails.profileImage"
                alt="img"
                class="responsive"
                v-if="adminDetails.profileImage && adminDetails.profileImage != 'test.jop'"
              />
              <img v-bind:src="$defaultImage" alt="img" class="responsive" v-else/>
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Title</label>
            <p class="text-dark">{{ adminDetails.title }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Name</label>
            <p class="text-dark">{{ adminDetails.fullName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Centre</label>
            <p class="text-dark">{{ adminDetails.companyName | capitalize }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Street Address</label>
            <p class="text-dark">{{ adminDetails.address.street }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Phone Number</label>
            <p class="text-dark">{{ adminDetails.mobileNumber }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Username</label>
            <p class="text-dark">{{ adminDetails.email }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">State</label>
            <p class="text-dark">{{ adminDetails.address ? adminDetails.address.state : '' }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Country</label>
            <p class="text-dark">{{ adminDetails.country ? adminDetails.country.name : '' }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Post Code</label>
            <p class="text-dark">{{ adminDetails.address ? adminDetails.address.postCode : '' }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
          <div class="w-full p-5">
            <label class="font-semibold text-dark">Account Status</label>
            <p
              class="font-semibold"
            >{{ adminDetails.accountStatus === 'Active' ? "Enabled" : "Disabled" }}</p>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card v-if="editAdmin === true">
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex item-center">
            <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'director-learning-centre-view', params:{ id: adminDetails.learningCenterId }}"
            >{{ adminDetails ? adminDetails.learningcenterDetails[0].name : '' }}
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a
              href="javascript:void(0)"
              class
              @click="editAdmin = false"
            >{{ this.adminDetails.fullName }}</a>

            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>Edit Admin</a>
          </li>
        </ul>
      </div>
      <div class="w-full md:w-1/2">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-w="12" class="my-8">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64 flex items-center">
                <img :src="logo" alt="img" class="responsive"/>
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex mt-5 w-full">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button @click="$refs.updateImgInput.click()">Edit</vs-button>
                <vs-button @click="logo = null" class="ml-8" color="danger">Remove Photo</vs-button>
              </div>
            </template>

            <div class="upload-img mt-5" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button @click="$refs.uploadImgInput.click()" type="border">Upload Photo</vs-button>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:mr-5">
              <label class="block mb-2">Title</label>
              <v-select
                :options="titleOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="titleFilter"
                class="mb-4 md:mb-0"
                data-vv-as="Title"
              />
              <vs-input
                type="hidden"
                name="title"
                data-vv-as="Title"
                v-model="title"
              />
              <span class="text-danger text-sm">{{ errors.first("title") }}</span>

            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:ml-5">
              <vs-input
                :success="
                    !errors.first('fullName') && adminDetails.fullName != ''
                  "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('fullName') ? true : false"
                v-validate="'required|max:150'"
                name="fullName"
                data-vv-as="Full Name"
                label="Full Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.fullName"
              />
              <span class="text-danger text-sm">{{ errors.first("fullName") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:mr-5">
              <vs-input
                :success="!errors.first('email') && adminDetails.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="Email"
                label="Username"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.email"
              />
              <span class="text-danger text-sm">{{ errors.first("email") }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:ml-5">
              <vs-input
                :success="
                    !errors.first('mobileNumber') && adminDetails.mobileNumber != '' "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('mobileNumber') ? true : false"
                v-validate="'required|numeric'"
                name="mobileNumber"
                data-vv-as="Mobile Number"
                label="Contact Number"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.mobileNumber"
              />
              <span class="text-danger text-sm">{{ errors.first("mobileNumber") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:mr-5">
              <vs-input
                :success="
                    !errors.first('streetAddress') && adminDetails.address.street != '' "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('streetAddress') ? true : false"
                name="streetAddress"
                data-vv-as="Street Address"
                label="Street Address"
                icon-no-border
                icon="icon icon-home"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.address.street"
              />
              <span class="text-danger text-sm">{{ errors.first("streetAddress") }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:ml-5">
              <vs-input
                :success="
                    !errors.first('town') && adminDetails.address.suburb != '' "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('town') ? true : false"
                name="town"
                data-vv-as="town"
                label="Townm/City"
                icon-no-border
                icon="icon icon-home"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.address.suburb"
              />
              <span class="text-danger text-sm">{{ errors.first("town") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:mr-5">
              <vs-input
                :success="
                    !errors.first('postCode') && adminDetails.address.postCode != '' "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('postCode') ? true : false"
                name="postCode"
                data-vv-as="Post Code"
                label="Post Code"
                icon-no-border
                icon="icon"
                icon-pack="feather"
                class="w-full"
                v-model="adminDetails.address.postCode"
              />
              <span class="text-danger text-sm">{{ errors.first("postCode") }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:ml-5">
              <label class="block mb-2">Country</label>
              <v-select
                :options="countryOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="countryFilter"
                class="mb-4 md:mb-0"
                data-vv-as="Country"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:mr-5">
              <label class="block mb-2">State</label>
              <v-select
                :options="stateOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="stateFilter"
                class="mb-4 md:mb-0"
                data-vv-as="State"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2">
            <div class="w-full mt-5 md:ml-5">
              <label class="block mb-2">Account Status</label>
              <vs-switch v-model="accountStatus"/>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-10" vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-w="12">
            <div class="ml-auto">
              <vs-button @click="editAdmin = false" color="danger" class="mr-4">Cancel</vs-button>
              <vs-button @click="editHandler" :disabled="!isFormValid">Save</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  data() {
    return {
      adminDetails: {},
      editAdmin: false,
      adminId: this.$route.params.id,
      logo: null,
      image: null,
      countryOptions: [],
      stateOptions: [],
      new_upload: "no",
      mobileNumber: "",
      streetAddress: "",
      town: "",
      postCode: "",
      countryFilter: {label: "All", value: "all"},
      stateFilter: {label: "All", value: "all"},
      centerId: this.$route.params.center,
      accountStatus: false,
      title: '',
      titleFilter: {label: "Select Title", value: ""},
      titleOptions: [
        {label: "Mr.", value: "Mr."},
        {label: "Mrs.", value: "Mrs."},
        {label: "Miss.", value: "Miss."},
      ],
    };
  },
  components: {
    vSelect,
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.adminDetails.fullName &&
        this.adminDetails.mobileNumber &&
        this.adminDetails.email
      );
    },
  },
  methods: {
    ...mapActions("center", [
      "getDirectorAdminDetails",
      "getDirectorAllCountries",
      "editDirectorAdminDetails",
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.new_upload = "yes";
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async getDetails(id) {
      this.$vs.loading();
      await this.getDirectorAdminDetails(id).then(async (res) => {
        this.adminDetails = res.data.data;
        this.logo = this.adminDetails.profileImage;
        this.title = this.adminDetails.title;
        this.accountStatus =
          this.adminDetails.accountStatus == "Active" ? true : false;
        (this.titleFilter = {
          label: this.adminDetails.title,
          value: this.adminDetails.title,
        }),
          this.$vs.loading.close();
       await this.getDirectorAllCountries().then((res) => {
          this.countryOptions = this.$store.state.center.countryOptions;
          if (this.adminDetails.country) {
            let filter = {
              label: this.adminDetails.country.name ? this.adminDetails.country.name : '',
              value: this.adminDetails.country.name ? this.adminDetails.country.name : '',
            };

            let selectedCountry = _.find(this.countryOptions, filter);
            this.countryFilter = selectedCountry;
            this.stateOptions = this.countryFilter.states;
            this.stateFilter = {
              label: this.adminDetails.address.state ? this.adminDetails.address.state : '',
              value: this.adminDetails.address.state ? this.adminDetails.address.state : '',
            };
          }
        });
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },
    async editHandler() {
      await this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          /*this.adminDetails.address.state = this.stateFilter.value;
            this.adminDetails.accountStatus = this.accountStatus;
            this.adminDetails.profileImage = this.image;
            this.adminDetails.no_upload = this.no_upload; */

          let data = new FormData();

          data.append("_id", this.adminDetails._id);
          data.append("title", this.titleFilter.value);
          data.append("fullName", this.adminDetails.fullName);
          data.append("mobileNumber", this.adminDetails.mobileNumber);

          data.append("email", this.adminDetails.email);
          data.append("logo", this.image);
          data.append("new_upload", this.new_upload);

          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);

          data.append("streetAddress", this.adminDetails.address.street);
          data.append("town", this.adminDetails.address.suburb);
          data.append("postCode", this.adminDetails.address.postCode);
          data.append("status", this.accountStatus);

          let info = {
            adminId: this.adminDetails._id,
            details: data,
          };
          this.editDirectorAdminDetails(info).then((res) => {
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Center admin edited successfully.",
              "success"
            );
          });
          this.editAdmin = false;
          this.getDetails(this.adminDetails._id);

          this.$router.push({
            name: "director-learning-centre-view",
            params: {id: this.adminDetails.learningCenterId},
          });
        }
      });
    },
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      /* this.selectedCountry = this.countryFilter.value; */
    },
    /* stateFilter(obj){
      this.selectedState = this.stateFilter.value;
    }, */
    titleFilter(obj) {
      this.title = obj.value;
    }
  },
  created() {
    let id = this.$route.params.id;
    // console.log('test', this.$route.matched[1].meta)
    this.getDetails(id);
  },
};
</script>
